import {
    RightSizePricingComponent,
    RightSizePricingProps,
} from '@eventbrite/wagtail-components';
import React from 'react';
import { useTheme } from '../../../utils/context/theme-context';
import { THEME_TYPES, VARIANTS } from '../../../utils/types';
import { BaseModuleProps } from '../types';
import { convertContentfulVariantToEnum } from '../utils';
import './rightSizePricing.scss';

interface IRightSizePricingModule extends RightSizePricingProps {
    isContentFromContentful?: boolean;
}

const RightSizePricingModule: React.FC<
    IRightSizePricingModule & BaseModuleProps
> = ({ serviceFee, processingFee, env, variant, isContentFromContentful }) => {
    const theme = useTheme();

    if (isContentFromContentful) {
        variant = convertContentfulVariantToEnum(variant);
    }
    if (!variant) {
        variant =
            theme.theme === THEME_TYPES.CORE
                ? VARIANTS.PURE_WHITE
                : VARIANTS.CORE_GRAY;
    }

    const baseOptions = { variant };
    const [backgroundClass] = theme.generateStyles('background', baseOptions);
    const moduleOverrideClassByTheme = `mlp-right-size-pricing-module__${theme.theme}`; // use this class to handle theme specific component styling

    return (
        <section className={`${backgroundClass} ${moduleOverrideClassByTheme}`}>
            <div className="mlp-right-size-pricing-module module-landing-page__module-container-content">
                <RightSizePricingComponent
                    serviceFee={serviceFee}
                    processingFee={processingFee}
                    renderInnerComponentOnly
                    env={env}
                />
            </div>
        </section>
    );
};
export default RightSizePricingModule;
